
<style scoped lang="less">
.Info {
  background: #fff;
  padding: 20px;
  box-shadow: @shadow;
  .stu-module-header {
    background: #fff;
    padding-bottom: 10px;
  }
  .items {
    display: flex;
    align-items: center;
    margin-top: 20px;
    .label {
      min-width: 80px;
      width: 80px;
      font-weight: bold;
    }
    .value {
      padding-left: 10px;
    }
  }
  @media screen and (min-width: 1200px) {
    .longItems {
      width: 50%;
    }
  }
}
</style>
<template>
  <div class="Info">
    <div class="stu-module-header">
      <div class="stu-module-title">我要缴费</div>
    </div>
    <el-row>
      <el-col
        :sm="24"
        :md="12"
        :lg="6"
        v-for="(item, key) in label"
        :key="key"
        class="items"
        :class="key === 'payCreateAt' ? 'longItems' : ''"
      >
        <p class="label">{{ item }}:</p>
        <p class="value">{{ data[key] }}</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { STU_HOME_ROUTE } from "@/tools/const";
export default {
  name: "tuition-info",
  data() {
    return {
      label: {
        userName: "姓名",
        stuNo: "学号",
        payTotal: "缴费总额",
        needPay: "应缴费用",
        hasPay: "已缴费用",
        payCreateAt: "缴费时间",
      },
      data: {
        userName: "",
        stuNo: "",
        payTotal: 0,
        needPay: 0,
        hasPay: 0,
        payCreateAt: "",
      },
    };
  },
  methods: {
  },
};
</script>
