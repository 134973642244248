<template>
  <div class="Forms">
    <el-form
      ref="tuitionForm"
      class="form-item"
      :model="formData"
      size="medium"
      inline
    >
      <el-form-item label="缴费形式" prop="payStyle">
        <el-select v-model="formData.payStyle" placeholder="请选择" clearable>
          <el-option
            v-for="(item, index) in payStyleOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
<!--      <el-form-item label="费用" prop="feeType">-->
<!--        <el-select v-model="formData.feeType" placeholder="请选择" clearable>-->
<!--          <el-option-->
<!--            v-for="(item, index) in feeTypeOptions"-->
<!--            :key="index"-->
<!--            :label="item.label"-->
<!--            :value="item.value"-->
<!--          ></el-option>-->
<!--        </el-select>-->
<!--      </el-form-item>-->
      <el-form-item label="缴费状态" prop="payStatus">
        <el-select v-model="formData.payStatus" placeholder="请选择" clearable>
          <el-option
            v-for="(item, index) in payStatusOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开始时间" prop="startTime">
        <el-date-picker
          v-model="formData.startTime"
          type="datetime"
          format="yyyy/MM/dd HH:mm:ss"
          value-format="yyyy/MM/dd HH:mm:ss"
          placeholder="选择日期"
          clearable
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间" prop="endTime">
        <el-date-picker
          v-model="formData.endTime"
          type="datetime"
          format="yyyy/MM/dd HH:mm:ss"
          value-format="yyyy/MM/dd HH:mm:ss"
          placeholder="请选择结束时间"
          clearable
        ></el-date-picker>
      </el-form-item>

      <el-form-item :style="{ marginBottom: '0' }">
        <el-button type="primary" @click="query">查询</el-button>
        <el-button @click="resetForm">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  components: {},
  props: ["payStyleOptions", "feeTypeOptions", "payStatusOptions"],
  data() {
    return {
      formData: {
        payStyle: null,
        feeType: null,
        payStatus: null,
        startTime: null,
        endTime: null,
      },
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    query() {
      this.$emit("payMoneyQuery", { ...this.formData });
    },
    resetForm() {
      this.$refs["tuitionForm"].resetFields();
    },
  },
};
</script>
<style scoped lang="less">
.Forms {
  margin-top: 20px;
  padding: 20px;
  background: #fff;
  box-shadow: @shadow;
  .form-item {
    .el-form-item {
      margin: 10px 10px 10px 0;
    }
  }
}
</style>
