<template>
  <div class="Student-Tuition">
    <!-- <Info /> -->
    <div class="stu-module-header">
      <div class="stu-module-title"><el-button type="primary" @click="pay()">缴费入口</el-button></div>
      <div class="money" style="display: flex">
      <div class="stu-module-title" style="margin-right: 6vw">应缴费用:{{ moneyDate.amount }}元</div>
      <div class="stu-module-title" style="margin-right: 5vw">已缴费用:{{ moneyDate.payAmount }}元</div>
      <div class="stu-module-title" style="margin-right: 0vw">未缴费用:{{ moneyDate.unpaidAmount }}元</div>
      </div>
      </div>
    <Forms ref="forms"
      v-if="queryRoot"
      :payStyleOptions="payStyleOptions"
      :feeTypeOptions="feeTypeOptions"
      :payStatusOptions="payStatusOptions"
      @payMoneyQuery="payMoneyQuery"
    />
    <Tables
      :payStyleOptions="payStyleOptions"
      :feeTypeOptions="feeTypeOptions"
      :payStatusOptions="payStatusOptions"
      :query="query"
    />


    <el-dialog
        title="订单确认"
        :visible.sync="buyDialog"
        width="40%">
      <el-form label-position="left">
        <el-form-item label="应缴费用" label-width="100px">
          {{ tuition.feeTotalAmount }}元
        </el-form-item>
        <el-form-item label="每次缴费金额" label-width="100px">
          {{ tuition.paymentAmount }}元
        </el-form-item>
        <el-form-item label="备注" label-width="100px">
          <el-input v-model="tuition.remark" placeholder="请输入备注" clearable></el-input>
        </el-form-item>
        <el-form-item label="支付方式" label-width="100px">
          <el-radio-group v-model="tuition.payType">
            <el-radio :label="3" border style="width: 120px">
              <img src="@/assets/pay/wechat.png" style="width: 70px;position: absolute;top:5px;left:30px">
            </el-radio>
            <el-radio :label="1" border style="width: 120px">
              <img src="@/assets/pay/alipay.png" style="width: 70px;position: absolute;top:5px;left:30px">
            </el-radio>
          </el-radio-group>
          <div style="clear: both;"></div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="buyDialog = false">取 消</el-button>
        <el-button type="primary" @click="commitPay">确 定</el-button>
      </div>
    </el-dialog>


    <el-dialog
        :visible.sync="wxPayment.payImg"
        width="30%">
      <div style="text-align: center;width: 40%">
        <img :src="wxPayment.payUrl" style="margin-left: 8.5vw" alt="">
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeAll">我已完成支付</el-button>
      </div>
    </el-dialog>

    <el-dialog
        :visible.sync="zfbPayment.aliPay"
        width="30%">
      <div style="text-align: center">
        <p class="alertFoot">如果您遇到问题，可以拨打客服电话</p>
        <el-button type="primary" style="margin-top: 15px" @click="closeAll">我已完成支付</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import Info from "./module/Info.vue";
import Forms from "./module/Forms.vue";
import Tables from "./module/Tables.vue";

export default {
  name: "student-tuition",
  components: { Tables, Forms, Info },
  data() {
    return {
      orderId: null,
      buyDialog: false,
      zfbPayment: {
        aliPay: false,
      },
      wxPayment: {
        payImg: false,
        payUrl: '',
      },
      tuition: {
        feeTotalAmount: 0.0, //应缴学费总额
        paymentAmount: 0.0, //每次缴费金额
        payType: 3, //支付类型
        remark: null, //备注
      },
      payStyleOptions: [
        {
          label: "学生充值",
          value: "0",
        },
        {
          label: "管理员录入",
          value: "1",
        },
      ],
      feeTypeOptions: [
        {
          label: "学费",
          value: "0",
        },
        {
          label: "教材费",
          value: "1",
        },
        {
          label: "邮费",
          value: "2",
        },
      ],
      moneyDate:{
        amount: 0.0,
        payAmount: 0.0,
        unpaidAmount: 0.0,
      },
      payStatusOptions: [
        {
          label: "已作废",
          value: "-1",
        },
        {
          label: "已确认",
          value: "0",
        },
        {
          label: "未确认",
          value: "1",
        },
      ],
      query: null,
    };
  },
  computed: {
    queryRoot() {
      return this.$sysPerKey("eduFeeStudentDetail:queryFeeStuDetail");
    },
  },
  activated() {
      this.init()
    },
  methods: {
    payMoneyQuery(item) {
      this.query = item;
    },

    init(){
      this.openLoadingView();
      this.$axios_supermallData.get('/hnjxjy-core/eduOrder/queryEduFeeTuitionByStuId').then(res =>{
          if(res.data.code===2000){
            this.moneyDate = res.data.data
          }
          this.loadingView.close();
      }).catch((err) => {
        this.loadingView.close();
      });
    },
    queryTuition(){
      this.openLoadingView();
      this.$axios_supermallData.get("/hnjxjy-core/eduOrder/queryEduOrderByOrderType",{params:{orderType:1}}).then(resp =>{
        this.tuition.feeTotalAmount = resp.data.data.feeTotalAmount;
        this.tuition.paymentAmount = resp.data.data.paymentAmount;
        this.loadingView.close();
      }).catch(reason => {
        this.loadingView.close();
      })
    },
    pay(){
      if (null == this.moneyDate.unpaidAmount || this.moneyDate.unpaidAmount === 0){
        return this.$message.success("暂无缴费信息");
      }
      this.buyDialog = true;
      setTimeout(() => {
        this.queryTuition();
      }, 100);
    },
    commitPay(){
      let formData = new FormData();
      formData.append("orderType","1");
      formData.append("payType",this.tuition.payType);
      formData.append("remark",this.tuition.remark);
      this.openLoadingView();
      this.$axios_supermallData.post("/hnjxjy-core/eduOrder/insertEduOrderByOrderType",formData).then(resp => {
        if (resp.data.code === 2000){
          let paymentFormData = new FormData();
          this.orderId = resp.data.data;
          paymentFormData.append("id",resp.data.data); //订单id
          if (this.tuition.payType === 3){ //微信支付
            this.$axios_supermallData.post("/hnjxjy-core/wxService/wxPaymentOrder",paymentFormData).then((wxRes) => {
              if (wxRes.data.code === 2000) {
                this.wxPayment.payImg = true;
                this.$nextTick(() => {
                  let base = 'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=';
                  this.wxPayment.payUrl = base + wxRes.data.data.code_url;
                });
              }
              this.loadingView.close();
            }).catch((err) => {
              this.loadingView.close();
            });
          }else { //支付宝支付
            this.$axios_supermallData.post("/hnjxjy-core/alipay/goPay",paymentFormData).then((zfbRes) => {
              let dataObj = zfbRes.data;
              let dwSafari;
              dwSafari = window.open();
              dwSafari.document.open();
              let html = dataObj.replace(/[^\u0000-\u00FF]/g, function ($0) {
                return escape($0).replace(/(%u)(\w{4})/gi, "&#x$2;")
              });
              dwSafari.document.write("<html><head><title></title><meta charset='utf-8'><body>" + html + "</body></html>")
              dwSafari.document.forms[0].submit();
              dwSafari.document.close();
              this.zfbPayment.aliPay = true;
              this.loadingView.close();
            }).catch((err) => {
              this.loadingView.close();
            });
          }
        }else {
          this.loadingView.close();
        }
      }).catch((err) => {
        this.loadingView.close();
      });
    },
    closeAll() {
      this.openLoadingView()
      this.$axios_supermallData.get('/hnjxjy-core/alipay/verificationUserPay?id='+this.orderId).then(res=>{
        if(res.data.code===2000){
          if(res.data.data==="0"){
            this.$message.success("购买成功");
            this.loadingView.close();
            this.wxPayment.payImg = false;
            this.zfbPayment.aliPay = false
            this.buyDialog=false;
            this.init();
            this.$refs.forms.query();
          }else if(res.data.data==="1"){
            this.$message.error("未支付此订单");
            this.loadingView.close();
          }else if(res.data.data==="-1"){
            this.$message.error("购买失败,请重试");
            this.loadingView.close();
          }
        }else if(res.data.code===5002){
          this.$message.warning(res.data.message);
          this.loadingView.close();
        }else{
          this.$message.error(res.data.message);
          this.loadingView.close();
        }
      }).catch((err) => {
        this.loadingView.close();
      });
    },
  },
};
</script>

<style scoped lang="less">
.Student-Tuition {
  .stu-module-header {
    background: #fff;
    box-shadow: @shadow;
    padding: 20px;

  }
}
</style>