<template>
  <div class="Tables">
    <el-table
      :data="datalist"
      :height="370"
      v-loading="loading"
      ref="tableRef"
      stripe
    >
      <el-table-column label="序号" :width="60" align="center">
        <template slot-scope="scope">
          <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in columns"
        :prop="item.prop"
        :key="index"
        :label="item.label"
        align="center"
        :minWidth="item.width"
        :show-overflow-tooltip="item.tooltip"
      ></el-table-column>
      <!-- 
        :formatter="item.formatter ? propFM : null"
       -->
    </el-table>
    <el-pagination
      class="pagination"
      background
      @current-change="handleCurrentChange"
      :current-page="current"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="tableData.length"
    >
    </el-pagination>
  </div>
</template>

<script>
import { QueryFeeStuDetail } from "@/libs/api/student";
export default {
  name: "tuition-pay-tables",
  props: {
    payStyleOptions: Array,
    feeTypeOptions: Array,
    payStatusOptions: Array,
    query: Object,
  },
  data() {
    return {
      loading: false,
      allTable: [],
      tableData: [],
      queryTable: [],
      columns: [
        { prop: "confirmTime", label: "缴费时间", tooltip: true },
        { prop: "orderNo", label: "订单号", tooltip: true },
        { prop: "amount", label: "缴费金额" },
        { prop: "payStyles", label: "缴费形式", formatter: true },
        { prop: "feeTypes", label: "缴费类型", formatter: true },
        { prop: "payStatuss", label: "缴费状态", formatter: true },
        { prop: "invoiceStatuss", label: "发票状态" },
        { prop: "remark", label: "备注" },
      ],
      pageSize: 10,
      current: 1,
      total: 0,
    };
  },
  mounted() {
    // this.allTable = this.tableData;
    this.init();
  },
  watch: {
    // 查询列表
    query(n) {
      const [payStyle, feeType, payStatus, startTime, endTime] = [
        n.payStyle, // 缴费方式
        n.feeType, // 类型
        n.payStatus, // 状态
        n.startTime, // 开始时间
        n.endTime, // 结束时间
      ];
      this.current = 1;
      this.init({ payStyle, feeType, payStatus, startTime, endTime });
      // this.tableData = this.allTable.filter(
      //   (f) =>
      //     (payStyle ? f.payStyle === payStyle : f.payStyle) &&
      //     (feeType ? f.feeType === feeType : f.feeType) &&
      //     (payStatus ? f.payStatus === payStatus : f.payStatus) &&
      //     (startTime && endTime
      //       ? this.dateParse(f.confirmTime, startTime, endTime)
      //       : startTime
      //       ? this.dateParse(f.confirmTime, startTime, null)
      //       : endTime
      //       ? this.dateParse(f.confirmTime, null, endTime)
      //       : true)
      // );

      // this.queryTable = this.tableData;
    },
  },
  computed: {
    datalist() {
      return this.tableData.slice(
        (this.current - 1) * this.pageSize,
        this.current * this.pageSize
      );
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    init(params) {
      const data = params
        ? { ...params, studentId: this.user.id }
        : { studentId: this.user.id };
      this.loading = true;
      QueryFeeStuDetail(data)
        .then((res) => {
          this.tableData = res.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    dateParse(date, scope1, scope2) {
      const d = Date.parse(date.split(" ")[0]);
      const s1 = scope1 ? Date.parse(scope1) : null;
      const s2 = scope2 ? Date.parse(scope2) : null;
      return (s1 ? d >= s1 : true) && (s2 ? d <= s2 : true);
    },
    handleCurrentChange(page) {
      if (page) this.current = page;
    },
    propFM(row, col) {
      if (col.property === "payStyle") {
        return this.payStyleFM(this.payStyleOptions, row.payStyle);
      } else if (col.property === "feeType") {
        return this.feeTypeFM(this.feeTypeOptions, row.feeType);
      } else if (col.property === "payStatus") {
        return this.payStatusFM(this.payStatusOptions, row.payStatus);
      } else {
        return row[col.property];
      }
    },
    payStyleFM(options, val) {
      const f = options.find((f) => f.value === val);
      return f ? f.label : "";
    },
    feeTypeFM(options, val) {
      const f = options.find((f) => f.value === val);
      return f ? f.label : "";
    },
    payStatusFM(options, val) {
      const f = options.find((f) => f.value === val);
      return f ? f.label : "";
    },
  },
};
</script>

<style scoped lang="less">
.Tables {
  padding: 10px;
  background: #fff;
  margin-top: 20px;
  box-shadow: @shadow;
}
</style>