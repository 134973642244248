import request from '../request'
import qs from 'qs'
// 学籍异动 - 查询
const StudentChangeList = (q, data) => {
    return request.post(`stuStudentChange/list?${qs.stringify(q)}`, data).then(res => res.data);
}

// 学籍异动 - 添加
const StudentChangeAdd = (data) => {
    return request.post('stuStudentChange/add', data).then(res => res.data);
}
// 学籍异动 - 异动设置查询
const QueryEduChange = () => {
    return request.get(`eduChange/queryEduChange1`).then(res => res.data)
}
// 通知公告-学生阅读公告
const UpdateNoticeByNoticeIdAndStudentId = (data) => {
    return request.post('edunotice/updateNoticeByNoticeIdAndStudentId', data).then(res => res.data);
}
// 通知公告-查询
const QueryAllNoticeByStudent = (params) => {
    return request.get(`edunotice/queryAllNoticeByStudent`, {
        params
    }).then(res => res.data);
}

// 教学计划 - 查询自己所学课程及课程对应学期
const QueryTeachPlanByStudent = () => {
    return request.get('eduTeachPlan/queryTeachPlanByStudent').then(res => res.data);
}

// 学费信息
const QueryFeeStuDetail = (params) => {
    return request.get(`eduFeeStudentDetail/queryFeeStuDetail?${qs.stringify(params)}`).then(res => res.data)
}

// 考试信息 - 免试申请 - 查询
const QueryExemptionSubmit = (params) => {
    return request.get(`eduOnlineQa/queryExemptionSubmit?${qs.stringify(params)}`).then(res => res.data)
}
// 考试信息 - 免试申请 - 删除
const UpdateExemptionSubmit = (params) => {
    return request.get(`eduOnlineQa/updateExemptionSubmit?${qs.stringify(params)}`).then(res => res.data)
}
// 考试信息-免试-查询学生可以提交免试的课程
const QueryExemptionCourse = () => {
    return request.get(`eduOnlineQa/queryExemptionCourse`).then(res => res.data)
}

// 我的成绩-（基础课或者学位课）交互成绩更新
const UpdateMutualScore = (data) => {
    return request.post(`eduStudentScore/updateMutualScore`, data).then(res => res.data);
}
// 我的成绩-(基础课或者额学位课)成绩分页查询
const SelectStuScore = (params) => {
    return request.get(`eduStudentScore/selectStuScore?${qs.stringify(params)}`).then(res => res.data);
}

// 文件申请 - 文件申请分页查询
const QueryEduStuMat = (params) => {
    return request.get(`eduStudentMaterial/queryEduStuMat?${qs.stringify(params)}`).then(res => res.data);
}
// 文件申请 - 根据id查询文件申请内容
const QueryEduStuMatById = (params) => {
    return request.get(`eduStudentMaterial/queryEduStuMatById?${qs.stringify(params)}`).then(res => res.data);
}
// 文件申请 - 文件申请修改
const UpdateEduStudentMaterial = (data) => {
    return request.post(`eduStudentMaterial/updateEduStudentMaterial`, data).then(res => res.data);
}
// 文件申请 - 文件申请添加申请材料
const InsertEduStudentMaterial = (data) => {
    return request.post(`eduStudentMaterial/insertEduStudentMaterial`, data).then(res => res.data);
}
// 毕业填报 - 查询填报信息
const QueryGraduationReport = (params) => {
    return request.get(`eduOnlineQa/queryGraduationReport?${qs.stringify(params)}`).then(res => res.data);
}
// 毕业填报 - 上传
const AddGraduationReport = (data) => {
    return request.post(`eduOnlineQa/addGraduationReport`, data).then(res => res.data)
}

// 我的学位 - 信息查询
const QueryEduDegStuByStuId = (params) => {
    return request.get(`eduGraduateDegreeSet/queryEduDegStuByStuId?${qs.stringify(params)}`).then(res => res.data);
}

// 我的学位 - 学位外语成绩申报查询
const QueryStudentEnglish = (params) => {
    return request.get(`eduGraduateDegreeSet/queryStudentEnglish${qs.stringify(params)}`).then(res => res.data);
}
// 我的学位 - 根据id查询
const SelectEduGraduateDegreeEnglishByStudentId = (params) => {
    return request.get(`eduGraduateDegreeEnglish/selectEduGraduateDegreeEnglishByStudentId?${qs.stringify(params)}`).then(res => res.data);
}
// 我的学位-学位外语成绩删除
const DeleteEduEnglishById = (params) => {
    return request.get(`eduGraduateDegreeSet/deleteEduEnglishById?${qs.stringify(params)}`).then(res => res.data);
}

// 学位外语申报时间查询(如果当前时间在申报时间内则展示添加按钮，如果不在则展示不在申报时间内)
const QueryTime = () => {
    return request.get(`eduGraduateDegreeSet/queryTime`).then((res) => res.data)
}
// 省份下拉框
const QueryAllEduArea = () => {
    return request.get(`eduArea/queryAllEduArea`).then((res) => res.data)
}

export {
    StudentChangeList,
    StudentChangeAdd,
    QueryEduChange,
    UpdateNoticeByNoticeIdAndStudentId,
    QueryAllNoticeByStudent,
    QueryTeachPlanByStudent,
    QueryFeeStuDetail,
    QueryExemptionSubmit,
    UpdateExemptionSubmit,
    QueryExemptionCourse,
    UpdateMutualScore,
    SelectStuScore,
    QueryEduStuMat,
    QueryEduStuMatById,
    UpdateEduStudentMaterial,
    InsertEduStudentMaterial,
    QueryGraduationReport,
    AddGraduationReport,
    QueryEduDegStuByStuId,
    QueryStudentEnglish,
    SelectEduGraduateDegreeEnglishByStudentId,
    DeleteEduEnglishById,
    QueryTime,
    QueryAllEduArea
}